import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LoremIpsum = () => (
  <Layout>
    <SEO title="Lorem Ipsum" />
    <h2>Main Header</h2>

    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pellentesque sem eget mauris semper sollicitudin.
    Aliquam cursus ligula purus, nec tempor sem ullamcorper in. Aliquam blandit sagittis pharetra. Etiam eget luctus
    turpis, nec eleifend orci. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
    himenaeos. Aenean congue eu nibh et malesuada. Praesent egestas et ipsum eu scelerisque. Vivamus congue elementum
    dolor, non iaculis nibh blandit sed. Proin interdum eleifend neque vel imperdiet. Sed non lacinia massa. Aliquam eu
    neque at justo ullamcorper laoreet. Vivamus in velit eget velit sodales scelerisque ut vel nibh.</p>

    <p>Nunc mollis arcu quis dui vestibulum, sed pellentesque quam rhoncus. Cras mollis eu velit in commodo. Phasellus ante
    arcu, commodo eu luctus ut, pulvinar et magna. Phasellus vitae tincidunt sapien. Sed gravida ultricies libero, eget
    imperdiet sapien feugiat quis. In accumsan diam non lacus semper congue. Class aptent taciti sociosqu ad litora
    torquent per conubia nostra, per inceptos himenaeos. Maecenas iaculis enim sed auctor hendrerit. Pellentesque
    habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>

    <p>Aenean eu lectus tempus, pharetra ante eget, semper lectus. Praesent id ante tempor, interdum turpis vitae, interdum
    elit. In tempus efficitur sem vitae viverra. Nunc commodo orci in elit accumsan tempus. Curabitur efficitur suscipit
    fringilla. Praesent dapibus viverra nunc id bibendum. Cras quis urna semper felis pretium commodo. Nunc lacinia
    finibus mollis. Praesent a blandit tortor, at dapibus est. Integer sed iaculis erat. Suspendisse volutpat aliquam
    felis, ac tempor dui ornare eu. Praesent dapibus sapien id placerat viverra. Ut lobortis eros eu enim sollicitudin
    blandit. Sed ullamcorper congue commodo. Sed nunc orci, convallis non nisl non, eleifend dignissim urna.
    Pellentesque tincidunt in augue a tristique.</p>

    <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam quis dictum erat.
    Proin iaculis risus urna, nec pharetra libero consectetur quis. Nullam vestibulum, purus quis aliquet vehicula,
    justo mauris lacinia odio, eget luctus tellus dolor varius magna. Morbi eros quam, venenatis ut orci ut, lobortis
    venenatis lorem. Vestibulum lectus nunc, gravida ut bibendum a, suscipit non mi. Nulla a finibus nisi. Nullam ac
    porta nisl, ac vestibulum velit. Pellentesque vulputate magna eget lacus molestie vulputate eu nec turpis. Phasellus
    sodales lorem tincidunt sem scelerisque, a volutpat ligula pharetra. Vestibulum mattis egestas pulvinar.</p>

    <p>Aliquam tincidunt ligula mauris, vel volutpat massa maximus ut. Duis malesuada ut sem gravida efficitur. Etiam velit
    nisi, consectetur sed pharetra vitae, dignissim at sem. Class aptent taciti sociosqu ad litora torquent per conubia
    nostra, per inceptos himenaeos. Praesent orci lacus, porttitor eu arcu quis, bibendum fermentum nisl. Etiam nec
    bibendum risus. Nunc urna mauris, dictum eu eros dignissim, aliquet semper erat.</p>
  </Layout>
)

export default LoremIpsum
